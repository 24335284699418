<script>
import { computed } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import { useRetailer } from '/~/extensions/giftcards/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import DrawerIconHeader from '/~/components/drawer/components/drawer-icon-header.vue'

export default {
  name: 'drawer-e-purchase-terms',
  components: {
    DrawerIconHeader,
    BaseAsidePage,
  },
  props: {
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { retailer } = useRetailer()

    const terms = computed(() => {
      return {
        terms: retailer.value?.attributes?.terms_and_conditions,
        redemption: retailer.value?.attributes?.how_to_use_redemption,
        online: retailer.value?.attributes?.how_to_use_online,
        inStore: retailer.value?.attributes?.how_to_use_in_store,
        salesAssociate: retailer.value?.attributes?.how_to_use_sales_associate,
      }
    })
    const title = computed(
      () => `${retailer.value?.attributes?.name ?? ''} Terms and Conditions`
    )

    function back() {
      if (route.meta?.back) {
        router.push(route.meta.back)
      } else {
        router.back()
      }
    }

    return {
      retailer,
      terms,
      title,
      back,
    }
  },
}
</script>

<template>
  <base-aside-page
    class="bg-light [&_a]:break-words"
    body-class="bg-white"
    @keydown.esc="back"
  >
    <template #iconHeader>
      <div />
    </template>
    <drawer-icon-header :title="title" icon="purchase-terms" :action="back" />
    <div v-if="terms.redemption" v-html="terms.redemption" />
    <div v-if="terms.online" v-html="terms.online" />
    <div v-if="terms.inStore" v-html="terms.inStore" />
    <div v-if="terms.salesAssociate" v-html="terms.salesAssociate" />
    <div v-if="terms.terms" class="mt-6">
      <div v-html="terms.terms" />
    </div>
  </base-aside-page>
</template>
